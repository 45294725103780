<template>
  <el-container>
    <div class="w-100">
      <div class="content caution-detail">
        <el-card class="box-card min-h-600">
          <div class="flex space-between">
            <h3 class="mod-title"><span>72小时内预警明细</span></h3>
            <el-form>
              <el-form-item>
                <el-select
                  v-model="sortField"
                  placeholder="发布时间降序"
                  @change="sortChange"
                >
                  <el-option label="发布时间降序" value="pushTime"></el-option>
                  <el-option label="信息等级排列" value="grade"></el-option>
                  <el-option label="方案名称排列" value="planName"></el-option>
                  <el-option
                    label="预警类型排列"
                    value="warningType"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <!-- 无数据 -->
          <div class="empty" v-if="empty && !loading">
            <!-- <div class="empty" style="display:none"> -->
            <div>
              <img
                src="@/assets/images/common/empty/yjgl.svg"
                alt="通讯录"
                width="400"
                class="img"
                type="image/svg+xml"
              />
              <p><span>暂无预警消息</span></p>
            </div>
          </div>
          <!--@end 无数据 -->
          <!-- 有数据 -->
          <!--@end 有数据 -->
          <div v-loading="loading" v-else>
            <!-- <div> -->
            <div class="mar-t-20">
              <ul class="mod-list lh-60 average-list">
                <li>
                  <h3>
                    <span class="tag w-100">预警类型</span>
                    <span class="tag">方案名称</span>
                    <!-- <span class="tag">等级</span> -->
                    <span class="tag cont">内容</span>
                    <span class="tag">时间</span>
                    <span class="tag">查看</span>
                  </h3>
                </li>
                <!-- <li v-for="(o, index) in 10" :key="o" class="item">
                  <span class="tag iconfont icon-xunhuan w-100"
                    ><var class="mar-l-10">循环</var></span
                  ><span title="浦东新区词云" class="tag">浦东新区词云</span
                  ><span class="tag cont"
                    >314条相关数据|网媒:271|自媒体号:28|客户端:12|微信:3|博客:0|微博:0|报刊:0|推特:0|政务:0|脸书:0|视频:0|论坛:0|评论:0</span
                  ><span class="tag">2021-06-03 16:51:43</span
                  ><a class="tag">查看详情</a>
                </li> -->
                <li v-for="(row, index) in data" :key="index" class="item">
                  <span :class="warningTypeClass(row.warningType)" class="tag"
                    ><var class="mar-l-10">{{ row.warningType }}</var></span
                  >
                  <span class="tag" :title="row.planName">{{
                    row.planName
                  }}</span>
                  <!-- <span class="tag">{{ row.grade }}</span> -->
                  <span class="tag cont" v-html="row.content"></span>
                  <span v-if="row.warningType == '实时'" class="tag">{{
                    row.publishTime
                  }}</span>
                  <span v-else class="tag">{{ row.pushTime }}</span>
                  <a
                    :href="row.url"
                    target="_blank"
                    v-if="row.warningType == '实时'"
                    class="tag"
                    >查看原文</a
                  >
                  <a v-else @click="toDetail(row.messageId)" class="tag"
                    >查看详情</a
                  >
                </li>
              </ul>
            </div>
            <!-- 分页 -->
            <div class="page-bottom mar-t-30">
              <el-pagination
                :hide-on-single-page="singPage"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </el-container>
</template>
<script>
import { lists as pushList } from '@/api/caution';
export default {
  name: 'caution-detail',
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      data: [],
      sortField: 'pushTime',
      empty: false,
      loading: false,
      singPage: true
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    warningTypeClass(type) {
      switch (type) {
        case '实时':
          return 'iconfont icon-shishi';
        case '定时':
          return 'iconfont icon-shijian';
        case '循环':
          return 'iconfont icon-xunhuan';
      }
    },
    toPlan() {
      this.$router.push({ path: '/yq' });
    },
    loadData() {
      let params = {
        customer_id: this.$store.state.user.user.customer_id,
        current_page: this.currentPage,
        sort: this.sortField
      };
      this.loading = true;
      pushList(params)
        .then(res => {
          if (res.data.state) {
            let result = res.data.data;
            this.total = result.total;
            this.empty = _.size(result.msg) < 1 ? true : false;
            this.data = result.msg;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(err => {
          window.console.error(err);
          this.$message.error('获取预警明细失败，服务错误');
        });
    },
    toDetail(messageId) {
      window.open(`/caution/list?messageId=${messageId}`, '_blank');
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    sortChange(field) {
      this.currentPage = 1;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.caution-detail .mod-title {
  margin-left: 0;
  border: none;
  padding-left: 0;
}
.caution-detail .mod-title span:first-child {
  padding-left: 20px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.caution-detail .mod-title {
  margin-left: 0;
}
.caution-detail >>> .el-card__body {
  padding: 30px 40px;
}
.caution-detail .mod-list-title1 {
  width: 13%;
}
.caution-detail .mod-list-title2 {
  width: 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 1%;
}
.caution-detail li {
  border-bottom: 1px solid rgba(10, 10, 10, 0.1);
}
.caution-detail li:last-child {
  border: none;
}
/* .caution-detail li .tag {
  text-align: left;
  width: 12%;
}
.caution-detail li .iconfont var {
  margin-left: 5px;
  font-size: 12px;
}
.caution-detail li .tag:last-child,
.caution-detail li a.tag:last-child {
  text-align: right;
  float: left;
  width: 9%;
} */
.caution-detail li .tag {
  padding: 0;
  flex: 1;
}
.caution-detail li .tag:last-child {
  cursor: pointer;
}
.caution-detail .average-list .cont {
  flex: 4;
  text-align: left;
}
.caution-detail .average-list .iconfont {
  margin-left: 0;
}
.caution-detail .average-list .iconfont var {
  font-size: 12px;
}
</style>
